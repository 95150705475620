<template>
  <section
    id="dashboard-analytics"
    class="task__list_page"
  >
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mt-1">
              Tasks
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="filter__options">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <div class="date__picker_block">
            <b-form-group
              label=""
            >
              <b-input-group>
                <flat-pickr
                  id="main-date"
                  v-model="dueDate"
                  class="form-control"
                  placeholder="Select Date"
                  :config="flatPickrConfigMain"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="CalendarIcon"
                    class="cursor-pointer"
                    data-toggle
                    size="18"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="icon-search-wrapper">
            <b-form-group>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="icons-search"
                  v-model="searchQuery"
                  placeholder="Search for a request"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="right_section"
        >
          <div class="demo-inline-spacing">
            <!-- primary -->
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :text="selectedTaskType"
              variant="outline-primary"
            >
              <b-dropdown-item
                @click="changeTaskType('My Tasks')"
              >
                My Tasks
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeTaskType('Unassigned')"
              >
                Unassigned
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeTaskType('All Tasks')"
              >
                All Tasks
              </b-dropdown-item>
            </b-dropdown>
            <!-- primary -->
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :text="selectedStatusType"
              variant="outline-primary"
            >
              <b-dropdown-item
                @click="changeStatusType('All Statuses')"
              >
                All Statuses
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeStatusType('Completed')"
              >
                Completed
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeStatusType('Pending')"
              >
                Pending
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeStatusType('Skipped')"
              >
                Skipped
              </b-dropdown-item>
            </b-dropdown>
            <div class="d-flex align-items-center justify-content-end">
              <b-dropdown
                id="dropdown-form-all-service-request"
                ref="filter_dropdown_all_service_request"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                </template>
                <task-filter
                  :status.sync="status"
                  :due-date.sync="dueDate"
                  :status-data-options="statusDataOptions"
                  :request-type.sync="requestType"
                  :request-type-options="requestTypeOptions"
                  :assignee.sync="assigneeFilter"
                  :assignee-options="assigneeOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <div
      id="icons-container"
      class=""
    >
      <b-row class="match-height">
        <b-col
          v-for="(task, key) in serviceRequestTasks"
          :key="key"
          lg="4"
          md="4"
        >
          <b-card
            no-body
            class="sticky_sidebar mb-50 low-opacity"
          >
            <div class="event-info p-1">
              <div class="card_header">
                <div class="card_header_left">
                  <div v-if="task.assignees.length">
                    <div
                      v-for="(assignee, index) in task.assignees"
                      :key="index"
                    >
                      <b-avatar
                        v-if="assignee.image"
                        :src="assignee.image"
                      />
                      <b-avatar
                        v-else
                        :text="resolveAcronymName(assignee.name)"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <b-button
                      variant="primary"
                      @click="claimTask(task._id)"
                    >
                      <feather-icon
                        icon="UserPlusIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">Claim Task</span>
                    </b-button>
                  </div>
                </div>
                <div class="card_header_right">
                  <feather-icon
                    icon="ClockIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span v-if="task.dueDate">{{ dateFormatWithTime(task.dueDate) }}</span>
                  <span v-else>No Due Date</span>
                </div>
              </div>
              <h5>
                <b-link
                  :to="{ name: 'operation-service-requests-tasks-show', params: { id: task._id } }"
                >
                  {{ task.taskName }}
                </b-link>
              </h5>
              <p><strong>Applicant:</strong> {{ task.serviceRequest.serviceForm.customerName }} • ({{ task.serviceRequest.serviceForm.customerContact }})</p>
              <p><strong>Deceased:</strong> {{ task.serviceRequest.serviceForm.deceasedName }}</p>
              <div class="card_footer">
                <b-badge
                  :variant="resolveTaskStatusVariant(task.status)"
                  class="text-capitalize mr-50"
                >
                  {{ task.status }}
                </b-badge>
                <b-badge
                  variant="light-secondary"
                  class="text-capitalize"
                >
                  {{ task.serviceRequest.workFlow.name }}
                </b-badge>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div
      v-if="totalTasks > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalTasks"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import {
  BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BCard, VBTooltip, BRow, BCol, BBadge, BAvatar,
  BPagination, BLink, BButton, BDropdown, BDropdownItem, BInputGroupAppend,
} from 'bootstrap-vue'
import { useClipboard } from '@vueuse/core'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import moment from 'moment'
import TaskFilter from './TaskFilter.vue'

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,

    TaskFilter,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      searchQuery: '',
      serviceRequestTasks: [],
      dueDate: moment().format('DD/MM/YYYY'),
      dueDateMain: moment().format('DD/MM/YYYY'),
      status: this.$route.query.status || 'all',
      requestType: [],
      assigneeFilter: [],
      perPage: 9,
      currentPage: this.$route.query.page || 1,
      totalTasks: 0,
      sortOrder: false,
      initialLoad: true,
      sortBy: 'createdAt',
      selectedTaskType: this.$route.query.selectedTaskType || 'My Tasks',
      selectedStatusType: 'All Statuses',
      statusDataOptions: [
        { title: 'Completed', code: 'completed' },
        { title: 'Pending', code: 'pending' },
        { title: 'Skipped', code: 'skipped' },
      ],
      requestTypeOptions: [],
      assigneeOptions: [],
      flatPickrConfigMain: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, defaultDate: new Date(), disableMobile: true,
      },
    }
  },
  watch: {
    searchQuery() {
      this.currentPage = 1
      this.fetchTaskData()
    },
    currentPage() {
      this.fetchTaskData()
    },
    dueDate() {
      this.currentPage = 1
      this.fetchTaskData()
    },
    status(statusVal) {
      if (statusVal === 'all') {
        this.selectedStatusType = 'All Statuses'
      } else if (statusVal === 'completed') {
        this.selectedStatusType = 'Completed'
      } else if (statusVal === 'pending') {
        this.selectedStatusType = 'Pending'
      } else if (statusVal === 'skipped') {
        this.selectedStatusType = 'Skipped'
      } else {
        this.selectedStatusType = 'All Statuses'
      }
      this.currentPage = 1
      this.fetchTaskData()
    },
    requestType() {
      this.currentPage = 1
      this.fetchTaskData()
    },
    assigneeFilter() {
      this.currentPage = 1
      this.fetchTaskData()
    },
  },
  created() {
    const statusVal = this.$route.query.status
    if (statusVal === 'all') {
      this.selectedStatusType = 'All Statuses'
    } else if (statusVal === 'completed') {
      this.selectedStatusType = 'Completed'
    } else if (statusVal === 'pending') {
      this.selectedStatusType = 'Pending'
    } else if (statusVal === 'skipped') {
      this.selectedStatusType = 'Skipped'
    } else {
      this.selectedStatusType = 'All Statuses'
    }
    this.fetchTaskData()
    this.$http.get('operation/service-forms/respond-with/duty-officers-workflow')
      .then(response => {
        const workFlowCats = response.data.workFlowCats ?? []
        this.assigneeOptions = response.data.dutyOfficers ?? []

        workFlowCats.forEach(element => {
          this.requestTypeOptions.push({
            category: element.name,
            name: null,

            _id: element._id,
          })
          element.workFlows.forEach(flow => {
            this.requestTypeOptions.push({
              name: flow.name,

              _id: flow._id,
            })
          })
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    fetchTaskData() {
      this.$http.get('operation/service-requests/tasks/all',
        {
          params: {
            search: this.searchQuery,
            status: this.status,
            dueDate: this.dueDate,
            requestType: this.requestType,
            assignee: this.assigneeFilter,
            selectedTaskType: this.selectedTaskType,
            perPage: this.perPage,
            page: this.currentPage,
            sortBy: this.sortBy,
            sortOrder: this.sortOrder,
          },
        })
        .then(response => {
          this.serviceRequestTasks = response.data.serviceRequestTasks || []
          this.totalTasks = response.data.totalTasks || 0
          if (!this.initialLoad) {
            this.$router.replace({
              query: {
                search: this.searchQuery,
                status: this.status,
                dueDate: this.dueDate,
                requestType: this.requestType,
                assignee: this.assigneeFilter,
                selectedTaskType: this.selectedTaskType,
                perPage: this.perPage,
                page: this.currentPage,
                sortBy: this.sortBy,
                sortOrder: this.sortOrder,
              },
            }).catch(() => {})
          }
          this.initialLoad = false
          // this.hideFilter()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    resolveTaskStatusVariant(status) {
      if (status === 'completed') return 'light-success'
      if (status === 'skipped') return 'light-danger'
      if (status === 'pending') return 'light-warning'
      return 'light-info'
    },

    changeTaskType(type) {
      this.selectedTaskType = type
      this.currentPage = 1
      this.fetchTaskData()
    },
    changeStatusType(status) {
      this.selectedStatusType = status
      if (status === 'All Statuses') {
        this.status = 'all'
      }
      if (status === 'Completed') {
        this.status = 'completed'
      }
      if (status === 'Pending') {
        this.status = 'pending'
      }
      if (status === 'Skipped') {
        this.status = 'skipped'
      }
    },
    hideFilter() {
      this.$refs.filter_dropdown_all_service_request.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_all_service_request.show(true)
    },
    claimTask(id) {
      const task = this.serviceRequestTasks.find(o => o._id === id)
      let assignee = ''
      if (task.serviceRequest.assignees.length) {
        assignee = task.serviceRequest.assignees[0].name
      }
      this.$swal({
        title: 'Are you sure you want to claim this task?',
        html: `System will send a message to ${assignee} (Assignee of the service request) that you will be helping this task.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/claim_icon.svg'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Proceed',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()

            formData.append('serviceRequestTask', id)

            this.$http.patch('operation/service-requests/task/claim', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                const foundIndex = this.serviceRequestTasks.findIndex(o => o._id === id)
                this.$set(this.serviceRequestTasks, foundIndex, response.data.data)
                this.$swal({
                  title: 'Task has been claimed.',
                  html: '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },
  setup() {
    const toast = useToast()
    const { copy } = useClipboard()

    const copyIconName = iconName => {
      copy(iconName)

      toast({
        component: ToastificationContent,
        props: {
          title: 'Icon name copied',
          icon: 'CopyIcon',
          variant: 'success',
        },
      })
    }

    return {
      copyIconName,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
